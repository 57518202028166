<template>
    <img
        :id="name"
        :srcset="srcset"
        sizes="(max-width: 640px) 640px, (max-width: 768px) 768px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, 1536px"
        :src="src"
        :alt="alt" />
</template>

<script setup>
defineOptions({
    inheritAttrs: true,
});
const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    images: {
        type: [String, Object],
        required: true,
    },
    placeholder: {
        type: String,
        default: null,
    },
    alt: {
        type: String,
        default: null,
    },
});

// Build the source set according to device pixel ratio
const pixelRatio = window.devicePixelRatio;
let images = props.images;
if (images && typeof images === 'object') {
    images = [];
    // Object
    Object.keys(props.images).forEach((key) => images.push({[key]: props.images[key]}));
}
const srcset =
    !images || typeof images === 'string'
        ? null
        : images
              .map((image) => `${Object.values(image)[0]} ${parseInt(Object.keys(image)[0]) * pixelRatio}w`)
              .join(',');

// Use the first (smaller) image from srcset as default src
const src = typeof !images || images === 'string' ? images : images.map((image) => Object.values(image)[0]).pop();
</script>
